import React from "react"
import { I18nextProvider } from "react-i18next"
import i18next from "i18next"
import { ParallaxProvider } from 'react-scroll-parallax'
import "./src/styles/global.css"

export const wrapRootElement = ({ element }) => {
  return (
    <I18nextProvider i18n={i18next}>
      <ParallaxProvider>
        {element}
      </ParallaxProvider>
    </I18nextProvider>
  )
}